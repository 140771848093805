<template>
  <v-container fluid px-0>
    <v-layout row wrap>
      <v-flex xs12 hidden-sm-and-up mb-1>
        <div
          class="d-flex align-center justify-center"
          @click="$router.replace('/')"
        >
          <img
            :src="require('@/assets/img/logo.png')"
            alt="logo"
            height="20px"
            width="auto"
            class="d-inline mr-1"
          />
          <span class="d-inline" v-text="'PILETIVAHETUS'">PILETIVAHETUS</span>
        </div>
      </v-flex>
      <v-flex xs10 sm6 offset-sm3 mt-3 mx-auto>
        <forgot-password-form />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { firebase } from '@firebase/app'

export default {
  components: {
    ForgotPasswordForm: () =>
      import(
        /* webpackChunkName: "ForgotPasswordForm" */ '@/components/Login/ForgotPasswordForm'
      )
  },
  data() {
    return {
      email: '',
      emailSent: false,
      error: null,
      timer: null,
      totalTime: 10,
      countdownInAction: false
    }
  },
  computed: {
    loading() {
      return this.error === undefined
    },
    minutes() {
      const minutes = Math.floor(this.totalTime / 60)
      return this.padTime(minutes)
    },
    seconds() {
      const seconds = this.totalTime - this.minutes * 60
      return this.padTime(seconds)
    }
  },
  methods: {
    async forgotPassword() {
      this.error = undefined
      try {
        await firebase.auth().sendPasswordResetEmail(this.email)
        this.emailSent = true
        this.error = null
        setTimeout(() => {
          this.emailSent = false
        }, 2000)
        this.startTimer()
      } catch (error) {
        this.error = error
      }
    },
    startTimer() {
      this.countdownInAction = true
      this.timer = setInterval(() => this.countdown(), 1000)
    },
    stopTimer() {
      clearInterval(this.timer)
      this.timer = null
    },
    resetTimer() {
      this.totalTime = 10
      clearInterval(this.timer)
      this.timer = null
    },
    padTime(time) {
      return (time < 10 ? '0' : '') + time
    },
    countdown() {
      if (this.totalTime >= 1) {
        this.totalTime -= 1
      } else {
        this.totalTime = 0
        this.resetTimer()
        this.countdownInAction = false
      }
    }
  },
  head: {
    title() {
      return {
        inner: 'Unustasin parooli '
      }
    },
    meta: [
      {
        name: 'description',
        content: 'Vaheta parooli ära',
        id: 'desc'
      }
    ]
  }
}
</script>
